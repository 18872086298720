import { ViewPort } from "models/constants/styling";
import Configuration from "./components/configuration";
import Viewer from "./components/viewer";
import { useState } from "react";

const Reports = () => {
  const [data, setData] = useState<any>(null);
  return (
    <div
      className={`mt-6 grid w-full grid-cols-1 gap-y-4 ${ViewPort.contentFitHeight}`}
    >
      <div className="grid grid-cols-1 gap-0 lg:grid-cols-4 lg:gap-4">
        <div className="col-span-1 grid gap-2">
          <Configuration
            onData={(data) => {
              setData(data);
            }}
          />
        </div>
        <div className="col-span-3 gap-2">
          <Viewer data={data} />
        </div>
      </div>
    </div>
  );
};

export default Reports;
