/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import routes from "routes";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { userFullname } from "store/user/userSelectors";
import SidenavCard from "widgets/cards/sidenav";

function SidebarHorizon(props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  variant?: string;
  [x: string]: any;
}) {
  const { open, onClose, variant, mini, hovered, setHovered } = props;
  const { t } = useTranslation();
  const fullname = useSelector(userFullname, shallowEqual);
  const savedAccountContext: any = localStorage.getItem("accountContext");

  return (
    <div
      className={`sm:none  ${
        mini === false
          ? "w-[250px]"
          : mini === true && hovered === true
          ? "w-[285px]"
          : "w-[250px] xl:!w-[120px]"
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === "auth" ? "xl:hidden" : "xl:block"
      } ${open ? "" : "-translate-x-[105%]"}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <SidenavCard
        extra={`round-none w-full h-[100vh] sm:mr-4 sm:my-4 md:my-0 bg-brand-50`}
      >
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute right-4 top-4 block cursor-pointer text-secondary xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div className={`mt-10 flex items-center justify-center`}>
                <div
                  className={`heading-xl uppercase text-secondary ${
                    mini === false
                      ? "block"
                      : mini === true && hovered === true
                      ? "block"
                      : "hidden"
                  }`}
                >
                  {t("app.name")} <span className="font-medium">PRO</span>
                </div>
              </div>
              <div className="mb-7 mt-10 h-px bg-gray-200 dark:bg-white/10" />
              {/* Nav item */}
              <ul>
                <Links
                  mini={mini}
                  hovered={hovered}
                  routes={routes}
                  onMenuItemClick={() => onClose}
                />
              </ul>
            </div>
          </div>
        </Scrollbars>
        {/* <div className="mb-5 ml-5 text-white">Account: </div> */}
        <div className="mb-5 ml-5 text-white">
          <span>Account: </span>
          <span className="font-semibold">
            {savedAccountContext ? JSON.parse(savedAccountContext)?.name : ""}
          </span>
        </div>
      </SidenavCard>
    </div>
  );
}

export default SidebarHorizon;
