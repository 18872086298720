import { reportRaw, reportUsageBasic } from "api/report";
import { RawReqBody, UsageReqBody } from "api/types/report";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/appSlice";
import { formatDateToZ } from "util/datelib";
import GenericCard from "widgets/cards/generic";
import { ScrollMode, SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import CircleButton from "widgets/shared/buttons/circle-button";
import { MdFileDownload } from "react-icons/md";

interface MyDocumentProps {
  data: any;
}

const ViewerPdf: React.FC<MyDocumentProps> = ({ data }) => {
  const [files, setFiles] = useState<any>();
  const [viewerKey, setViewerKey] = useState<number>(0);
  const dispatch = useDispatch();

  // Generate Raw report
  const generateRawReport = async () => {
    dispatch(setLoading(true));
    const rawBody: RawReqBody = {
      siteId: data.site,
      snr: data.device,
      from: formatDateToZ(data.dateRange[0]),
      to: formatDateToZ(data.dateRange[1], true),
    };

    const { data: res, isLoading, status } = await reportRaw(rawBody);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      setFiles(res.url);
      setViewerKey((prevKey) => prevKey + 1);
    }
  };

  // Generate Usage Report
  const generateUsageReport = async () => {
    dispatch(setLoading(true));
    const rawBody: UsageReqBody = {
      siteId: data.site,
      snrs: data.devices,
      from: formatDateToZ(data.dateRange[0]),
      to: formatDateToZ(data.dateRange[1], true),
      type: "WATER",
    };
    if (data.reportType === 3) {
      rawBody.type = "ELECTRICITY";
    }
    const { data: res, isLoading, status } = await reportUsageBasic(rawBody);
    dispatch(setLoading(isLoading));

    if (status === 200) {
      setFiles(res.url);
      setViewerKey((prevKey) => prevKey + 1);
    }
  };

  useEffect(() => {
    if (data) {
      setFiles(null);
      if (data.reportType === 1) {
        generateRawReport();
      } else {
        generateUsageReport();
      }
    }
  }, [data]);

  // Download PDF file
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = files.pdfUrl;
    link.target = "_blank";
    link.download = "report.pdf";
    link.click();
  };

  // Download Excel file
  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = files.excelUrl;
    link.target = "_blank";
    link.download = "report.xlsx";
    link.click();
  };

  return (
    <GenericCard
      bgColor="bg-white"
      extra="h-full"
      topRight={
        <div className="flex gap-2">
          <CircleButton
            size="sm"
            variant="outline"
            icon={MdFileDownload}
            label={"DOWNLOAD PDF"}
            onClick={downloadPdf}
          />
          <CircleButton
            size="sm"
            variant="outline"
            icon={MdFileDownload}
            label={"DOWNLOAD EXCEL"}
            onClick={downloadExcel}
          />
        </div>
      }
    >
      <div className="flex max-h-[670px] w-full">
        {files?.pdfUrl && (
          <Viewer
            key={viewerKey}
            fileUrl={files?.pdfUrl}
            defaultScale={SpecialZoomLevel.PageWidth}
          />
        )}
      </div>
    </GenericCard>
  );
};

export default ViewerPdf;
